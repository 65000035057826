import { extend, override } from 'flarum/extend';
import app from 'flarum/app';
import LogInModal from 'flarum/components/LogInModal';
//import LogInButton from 'flarum/components/LogInButton';
import Alert from 'flarum/components/Alert';

// initialize firebase
import firebase from 'firebase/app';
import 'firebase/auth';
const firebaseConfig = {
    apiKey: "AIzaSyChC_q7ndofSmdTkMxSZuiANsOu6opVUlw",
    authDomain: "ef3047-prod.firebaseapp.com",
    databaseURL: "https://ef3047-prod.firebaseio.com",
    projectId: "ef3047-prod",
    appId: "1:1018754819244:web:4a229ca23016a7a623ab09"	
};

var firebaseInitialized = false;

app.initializers.add('saleksin-auth-firebase', () => {
	
    override(LogInModal.prototype, 'onsubmit', firebaseOnSubmit);
  
    function replaceLoginButton(items) {
        if (!items.has('logIn')) {
            return;
        }

        let loginUrl = app.forum.data.attributes['wuethrich44-sso.login_url'];

        items.replace('logIn',
            <button href="/auth/firebase" className="Button Button--primary Button--block" type="submit" title="Log In">
                {app.translator.trans('core.forum.header.log_in_link')} :-;
            </button>
        );
    }  
	
	function onErrorFiltered(errorHandler ) {
		return (e) => {
			if (!e.responseText.startsWith("<script>window.close")) {
				errorHandler(e);
			}
		}
	} 
	
  function firebaseOnSubmit(event) {
	console.log("my submit called");

	if (!firebaseInitialized) {
		firebase.initializeApp(firebaseConfig);
		firebaseInitialized = true;
		console.log("firebase initialized");
	}

	//if (event) event.preventDefault();
	
    this.loading = true;
	
    const identification = this.identification();
    const password = this.password();
    const remember = this.remember();
    
    const errorHandler = this.onerror.bind(this);	
	
	firebase.auth().signInWithEmailAndPassword(identification, password)
	.then( x => {
		console.log("authenticated", x);
		firebase.auth().currentUser.getIdToken(/* forceRefresh */ true)
		.then( idToken => {
			console.log("got token");
			const options = {errorHandler: onErrorFiltered(this.onerror.bind(this))}; 
			const data = {idToken};
			app.request(Object.assign({
			  method: 'POST',
			  url: app.forum.attribute('baseUrl') + '/auth/firebase',
			  data
			}, options))
			.then( x => {
				console.log("logged in");
				window.location.reload();
			    this.loaded.bind(this);
			})
			.catch( e => {
				if (e.responseText.startsWith("<script>window.close")) {
					console.log("logged in",x);
					window.location.reload();
					this.loaded.bind(this);
				}
			});
		})
		.catch( e => {
			this.loading = false;
			showAlert( e, errorHandler );
		});
	})
	.catch( e => {
        this.loading = false;
		showAlert( e, errorHandler );
	});
	//.catch(function(error) {
	  // Handle Errors here.
	//  var errorCode = error.code;
	//  var errorMessage = error.message;
	//  console.log("error", errorCode, errorMessage);
	//  this.onerror(error);
	//});
	
    //firebaseLogin({identification, password, remember}, {errorHandler: this.onerror.bind(this)})
    //  .then(
    //    () => window.location.reload(),
    //    this.loaded.bind(this)
    //  );	

	return false; 
  }	  
  
  function showAlert(e, errorHandler) {
	e.alert = new Alert({
		type: 'error',
		children: e.message
	});
	errorHandler(e);
	console.log(e);
  }  

  function firebaseLogin(data, options = {}) {
    return app.request(Object.assign({
      method: 'POST',
      url: app.forum.attribute('baseUrl') + '/auth/firebase',
      data
    }, options));
  }	
  
});
